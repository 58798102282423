export const optionData = [
  {
    icon:
      "https://res.cloudinary.com/zillaafrica/image/upload/v1646149799/merchant/Group_13003_hd6i47.svg",
    content:
      "We need more information about your business. This is needed to activate your account",
    type: "Activate store",
  },
  {
    icon:
      "https://res.cloudinary.com/zillaafrica/image/upload/v1646149799/merchant/Group_13004_hzhboz.svg",
    content:
      "Explore what’s possible with Zilla- how to get started and start accepting payments.",
    type: "Tell me more",
  },
  {
    icon:
      "https://res.cloudinary.com/zillaafrica/image/upload/v1646149799/merchant/Group_13005_zyodid.svg",
    content:
      "When you get stuck and need help, let’s know and we will be happy to help.",
    type: "Contact support",
  },
];

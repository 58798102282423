<template>
  <div>
    <Card class="card-container" p="p-6 md:p-10">
      <h4 class="text-lg font-extrabold">Getting started</h4>
      <p class="mt-4 font-emibold text-secondary">
        Welcome to your store! We are very excited to have you on board. Watch a
        special welcome video we made just for you here.
        <!-- Watch a
      special welcome video we made just for you here -->
      </p>
      <div class="mt-6 md:grid grid-cols-3 gap-12">
        <Option
          v-for="(options, index) in optionData"
          :key="index"
          :options="options"
        />
      </div>
    </Card>

    <div class="md:grid grid-cols-2 gap-4">
      <Card p="p-6 md:p-10" class="mt-4">
        <div>
          <h4 class="text-black text-lg font-extrabold">
            Add store link to your social bio(s)
          </h4>
          <p class="mt-2 text-secondary mb-5">
            To get your link, click the button below. You can learn how to make
            the most of your merchant link by clicking
            <a
              href="https://medium.com/zilla-merchants/make-the-most-of-your-merchant-link-eb10dd0af10b"
              class="font-semibold"
              target="blank"
            >
              here</a
            >
          </p>
          <SecondaryButton
            text="Copy store link"
            class="mx-auto"
            p="py-3 px-5"
            fontSize="text-sm"
            @click="copy(link)"
          />
        </div>
      </Card>
      <Card p="p-6 md:p-10" class="mt-4">
        <div>
          <h4 class="text-black text-lg font-extrabold">
            Check out resources that will help you get started
          </h4>
          <p class="mt-2 text-secondary">
            We have a database of articles, videos, FAQs and lots of resources
            that will help you get settled in as a Zilla merchant
          </p>
        </div>
        <div class="flex items-center mt-5">
          <a href="http://instagram.com/usezilla/" target="blank">
            <img
              class="h-8 w-8 md:h-12 md:w-12 mr-4"
              src="https://res.cloudinary.com/zillaafrica/image/upload/v1627394321/merchant/Instagram_xunsyg.svg"
              alt=""
            />
          </a>
          <a href="http://usezilla.medium.com/" target="blank">
            <img
              class="h-8 w-8 md:h-12 md:w-12 mr-4"
              src="https://res.cloudinary.com/zillaafrica/image/upload/v1627395357/merchant/Medium_oae8pj.svg"
              alt=""
            />
          </a>
          <a href="http://twitter.com/usezilla" target="blank">
            <img
              class="h-8 w-8 md:h-12 md:w-12 mr-4"
              src="https://res.cloudinary.com/zillaafrica/image/upload/v1627395466/merchant/Twitter_wvsjfa.svg"
              alt=""
            />
          </a>
        </div>
      </Card>
    </div>
    <Modal :display="activatedStoreModal" @close="closeActivateModal" title="">
      <div>
        <h2 class="text-center text-2xl font-bold">
          Activate your store to <br />
          start selling
        </h2>
        <p class="text-center text-dark pt-4">
          Your store is currently on sandbox. This is a test environment and you
          would not be able to perform real transactions yet in this mode. To
          start selling, do the following:
        </p>
        <ul class="p-4 list-disc">
          <li>
            Activate your store and get confirmed by us within 1 working day
          </li>
        </ul>
        <Button
          text="Activate store"
          width="w-full"
          class="my-4"
          @click="openActivateStore"
        />
        <p
          @click="closeActivateModal"
          class="text-center cursor-pointer text-brandPurple font-semibold"
        >
          Cancel
        </p>
      </div>
    </Modal>


    <ActivateForm :display="showActivate" @close="showActivate = false" />
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
import SecondaryButton from "@/UI/SecondaryButton";
import Button from "@/UI/Button";
import Option from "./Option";
import { optionData } from "./options";
import Hr from "@/UI/Hr";
import copy from "@/mixins/copy";
import Modal from "@/UI/Modal";
import ActivateForm from "./ActivateForm";
export default {
  mixins: [copy],
  components: {
    Option,
    Hr,
    SecondaryButton,
    Modal,
    Button,
    ActivateForm
  },
  data: () => ({
    optionData,
    link: "",
    showActivate: false
  }),
  computed: {
    ...mapState({
      merchant: state => state?.auth?.user,
      activatedStoreModal: state => state?.auth?.activatedStoreModal
    })
  },
  mounted() {
    this.link = `${this.merchant.merchantOutlet.paymentLink}`;
  },
  methods: {
    ...mapActions("auth", ["closeActivateStoreModal"]),
    openActivateStore() {
      this.closeActivateStoreModal(false);
      this.showActivate = true;
    },
    closeActivateModal() {
      this.closeActivateStoreModal(false);
    }
  }
};
</script>
<style scoped>
/* .card-container {
  min-height: calc(100vh - 10rem);
} */
.contact {
  display: grid;
  grid-template-columns: 70% 1fr;
  grid-gap: 15px;
}
@media (max-width: 768px) {
  .contact {
    display: block;
    text-align: center;
  }
}
</style>

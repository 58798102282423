<template>
  <Card class="flex options flex-col" p="p-8 mt-5 mt-0">
    <div class="option-icon grid place-items-center">
      <img :src="options.icon" alt="options.type" />
    </div>
    <p
      class="text-center option-description text-lightPurple-2 mx-auto w-10/12"
    >
      {{ options.content }}
    </p>
    <div class="button text-center justify-self-end">
      <SecondaryButton
        :text="
          options.type === 'Activate store' && activatedStore
            ? 'Setup completed'
            : options.type
        "
        class="mx-auto"
        :disabled="options.type === 'Activate store' && activatedStore"
        p="py-3"
        fontSize="text-sm"
        width="w-full"
        :background="
          options.type === 'Activate store'
            ? 'bg-brandPurple'
            : 'bg-lightPurple'
        "
        :color="
          options.type === 'Activate store' && !activatedStore
            ? 'text-white'
            : 'text-brandPurple'
        "
        @click="processActivation"
      />
    </div>
    <ActivateForm :display="showActivate" @close="showActivate = false" />
  </Card>
</template>
<script>
import SecondaryButton from "@/UI/SecondaryButton";
import ActivateForm from "../ActivateForm";
import { mapState } from "vuex";
``;
//   import Hr from "@/UI/Hr";
export default {
  props: {
    options: {
      type: Object,
      default: () => {}
    }
  },
  components: {
    SecondaryButton,
    ActivateForm
    //   Hr,
  },
  computed: {
    ...mapState({
      merchant: state => state?.auth?.user?.merchantUser,
      activatedStore: state => state?.auth?.activatedStore
    })
  },
  data: () => ({
    showActivate: false
  }),
  methods: {
    processActivation() {
      if (this.options.type === "Activate store") {
        this.showActivate = true;
      } else if (this.options.type === "Contact support") {
        window.fcWidget.open();
        window.fcWidget.setExternalId(`${this.merchant.email}`);
        window.fcWidget.user.setProperties({
          firstName: `${this.merchant.firstName}`,
          lastName: `${this.merchant.lastName} `,
          email: `${this.merchant.email} `
        });
      } else if (this.options.type === "Tell me more") {
        window.open(
          "https://usezilla.notion.site/usezilla/FAQs-b48268c8a6854054bb425f90d9977122"
        );
      }
    }
  }
};
</script>
<style scoped>
@import "~@/assets/styles/animation.css";
.option-icon {
  height: 80px;
  width: 80px;
  background: white;
  margin: 40px auto;
  border-radius: 16px;
}
.option-description {
  min-height: 8rem;
}
.options {
  border: 1px solid #ededf0;
}
</style>
